// extracted by mini-css-extract-plugin
export var CAEmbed = "micasa-module--CAEmbed--5ideh";
export var CAHeader = "micasa-module--CAHeader--QwdZl";
export var CAItem = "micasa-module--CAItem--T1YHX";
export var FigmaEmbed = "micasa-module--FigmaEmbed--fzj5S";
export var Figmaiframe = "micasa-module--Figmaiframe--oDv-B";
export var YTiframe = "micasa-module--YTiframe--g5c25";
export var anxietyImg = "micasa-module--anxietyImg--REQto";
export var award = "micasa-module--award--kOaCB";
export var awardText = "micasa-module--awardText--0qUkM";
export var backTop = "micasa-module--backTop--BBOCZ";
export var blockQuote = "micasa-module--blockQuote--+WJJN";
export var blueBar1 = "micasa-module--blueBar1--M70ZJ";
export var blueBar2 = "micasa-module--blueBar2--c5-b3";
export var blueBar3 = "micasa-module--blueBar3--bqLNz";
export var blueBullet = "micasa-module--blueBullet--AnJ+r";
export var blueText = "micasa-module--blueText--l6qUJ";
export var bodyText = "micasa-module--bodyText--73MCP";
export var bodyTextLight = "micasa-module--bodyTextLight--9ZQeY";
export var bodyTextSemiBold = "micasa-module--bodyTextSemiBold--HoI-Q";
export var borderedText = "micasa-module--borderedText--+GQAI";
export var botButton = "micasa-module--botButton--CndBO";
export var buttonsContainer = "micasa-module--buttonsContainer--YZMM2";
export var clientBody = "micasa-module--clientBody--4IwTn";
export var clientContainer = "micasa-module--clientContainer--OYzPR";
export var clientSubtext = "micasa-module--clientSubtext--kOdrl";
export var clientTextContainer = "micasa-module--clientTextContainer--ZuoZN";
export var competitiveAnalysisContainer = "micasa-module--competitiveAnalysisContainer--njA5W";
export var computerXImg = "micasa-module--computerXImg--n3hfz";
export var contributionBody = "micasa-module--contributionBody--9M-Nw";
export var contributionContainer = "micasa-module--contributionContainer--jZLCj";
export var contributionContent = "micasa-module--contributionContent--kET0R";
export var contributionImg = "micasa-module--contributionImg--vSc5f";
export var detailsHeader = "micasa-module--detailsHeader--ViO0K";
export var downArrow = "micasa-module--downArrow--N8a0U";
export var figureTitle = "micasa-module--figureTitle--PmGqj";
export var firstGifBlock = "micasa-module--firstGifBlock--F6Rv1";
export var flex = "micasa-module--flex--1IOXz";
export var flexBox = "micasa-module--flexBox--KjALa";
export var gatsbyImg = "micasa-module--gatsbyImg--RfiP3";
export var gifBlock = "micasa-module--gifBlock--niiH7";
export var gifBody = "micasa-module--gifBody--basiM";
export var gifText = "micasa-module--gifText--+6s5a";
export var heroContainer = "micasa-module--heroContainer--kIlap";
export var heroImage = "micasa-module--heroImage--jxn9s";
export var heroSubtext = "micasa-module--heroSubtext--ciW5p";
export var heroSubtextMobile = "micasa-module--heroSubtextMobile--FzipV";
export var heroText = "micasa-module--heroText--ODI05";
export var imageCaption = "micasa-module--imageCaption--RkRHa";
export var imageCaption2 = "micasa-module--imageCaption2--F3SPW";
export var indentedContainer = "micasa-module--indentedContainer--IMmJz";
export var indentedInner = "micasa-module--indentedInner--LLNK1";
export var indentedText = "micasa-module--indentedText--g2r8k";
export var innerDetailsContainer = "micasa-module--innerDetailsContainer--HFZhZ";
export var issueImgWrapper = "micasa-module--issueImgWrapper--e5vg9";
export var linkBody = "micasa-module--linkBody--WCzIE";
export var linkButton = "micasa-module--linkButton--KCvyJ";
export var marginTop1 = "micasa-module--marginTop1--M5k5L";
export var marginTop12 = "micasa-module--marginTop12--3rk8G";
export var marginTop1half = "micasa-module--marginTop1half--dVtvl";
export var marginTop2 = "micasa-module--marginTop2--qD77v";
export var marginTop3 = "micasa-module--marginTop3--hCFxS";
export var marginTop4 = "micasa-module--marginTop4--uHy5E";
export var marginTop5 = "micasa-module--marginTop5--mqAUR";
export var marginTop6 = "micasa-module--marginTop6--fxtTW";
export var marginTop7 = "micasa-module--marginTop7--HeOJC";
export var marginTop9 = "micasa-module--marginTop9--H0AYa";
export var mobileMarginTop4 = "micasa-module--mobileMarginTop4--DpfOT";
export var orderTwo = "micasa-module--orderTwo--NlB6e";
export var outterDetailsContainer = "micasa-module--outterDetailsContainer--Lxqn0";
export var ovSubheader = "micasa-module--ovSubheader--f4KOR";
export var ovSubheaderItem = "micasa-module--ovSubheaderItem--Y4jp8";
export var ovSubheaderItemNoMargin = "micasa-module--ovSubheaderItemNoMargin--RqpQW";
export var ovSubheaderText = "micasa-module--ovSubheaderText--ypWmI";
export var ovSubheadersContainer = "micasa-module--ovSubheadersContainer--H22RQ";
export var overviewGif = "micasa-module--overviewGif--5yqv3";
export var overviewInnerContainer = "micasa-module--overviewInnerContainer--IBeFK";
export var overviewOutterContainer = "micasa-module--overviewOutterContainer--w+xUD";
export var pageSubheader = "micasa-module--pageSubheader--+wJ3q";
export var participants1Img = "micasa-module--participants1Img--qATB+";
export var participants3Img = "micasa-module--participants3Img--Sa4wJ";
export var participants4Img = "micasa-module--participants4Img--QjDWF";
export var participantsContainer = "micasa-module--participantsContainer--2s5Fa";
export var participantsItem = "micasa-module--participantsItem--yw7xw";
export var participantsLast = "micasa-module--participantsLast--uAUpo";
export var participantsOutter = "micasa-module--participantsOutter--L3+Ay";
export var primaryIssues = "micasa-module--primaryIssues--1oRO8";
export var primaryIssuesFlex = "micasa-module--primaryIssuesFlex--17DDO";
export var primaryIssuesItem = "micasa-module--primaryIssuesItem--t3jbH";
export var problemBody = "micasa-module--problemBody--6Q3uL";
export var problemContent = "micasa-module--problemContent--SF0Pz";
export var problemImg = "micasa-module--problemImg--arpwB";
export var puzzleImg = "micasa-module--puzzleImg--l9Na-";
export var quoteBar = "micasa-module--quoteBar--HT9Ze";
export var quoteText = "micasa-module--quoteText--vmWYx";
export var resultsImg = "micasa-module--resultsImg--FvSAY";
export var roadmap = "micasa-module--roadmap--cupeg";
export var roadmapContainer = "micasa-module--roadmapContainer--rBoZE";
export var sectionContainer = "micasa-module--sectionContainer--vedCj";
export var sectionContainer2 = "micasa-module--sectionContainer2--hoEZy";
export var sectionHeader = "micasa-module--sectionHeader--yhS0J";
export var sketchImg = "micasa-module--sketchImg--r8tma";
export var sketchesContainer = "micasa-module--sketchesContainer--o1Xu6";
export var solutionBody = "micasa-module--solutionBody--7n0m2";
export var star = "micasa-module--star--ifto8";
export var sysRItem = "micasa-module--sysRItem--TJ3v6";
export var sysRequirements = "micasa-module--sysRequirements--PMRwh";
export var takeMeHome = "micasa-module--takeMeHome--LCpVk";
export var timelineContainer = "micasa-module--timelineContainer--kw37p";
export var timelineImg = "micasa-module--timelineImg--M0avc";
export var topMar6 = "micasa-module--topMar6--GD97u";
export var topPageContainer = "micasa-module--topPageContainer--iJ71F";
export var userTestingImg = "micasa-module--userTestingImg--ztXAi";
export var watlLogo = "micasa-module--watlLogo--VKDti";
export var watlWrapper = "micasa-module--watlWrapper--jKCiq";
export var wireframesContainer = "micasa-module--wireframesContainer--j593G";
export var wireframesImg = "micasa-module--wireframesImg--hsyxA";