import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as miCasaStyles from "../styles/micasa.module.css"
import Header from "../components/header"
import Footer from "../components/footer"
import Seo from "../components/seo"
import learnVideo from "../assets/micasa/learn2.mp4"
import resourcesVideo from "../assets/micasa/Resources2.mp4"
import tooltipsVideo from "../assets/micasa/Tooltips2.mp4"
import downArrow from "../assets/micasa/downArrow.svg"

const MiCasa = () => (
    <main>
        <div id={miCasaStyles.topPageContainer}>
            <Seo title="My house my rights"/>
            <Header home={false}/>
            <section id={miCasaStyles.heroContainer}>
                <div id={miCasaStyles.heroText}>
                    My House<br/>
                    My Rights
                    <div id={miCasaStyles.heroSubtext}>
                        Creating a website that uses storytelling to help immigrant families of Atlanta understand and exercise their rights as tenants. In partnership with the Atlanta city government
                    </div>
                </div>
                <StaticImage 
                    src="../assets/micasa/micasahero.png"
                    alt="Images of the Mi Casa Mis Derechos art"
                    className={miCasaStyles.heroImage}
                    quality={100}
                    loading="eager"
                    />
                <div id={miCasaStyles.heroSubtextMobile}>
                    Creating a website that uses storytelling to help immigrant families of Atlanta understand and exercise their rights as tenants. In partnership with the Atlanta city government
                </div>
            </section>
        </div>

        <section id={miCasaStyles.overviewOutterContainer}>
            <div id={miCasaStyles.overviewInnerContainer}>
                        <div class = {miCasaStyles.sectionHeader}>
                            Overview
                        </div>
                        <div id={miCasaStyles.ovSubheadersContainer}>
                            <div class={miCasaStyles.ovSubheaderItem}>
                                <div class={miCasaStyles.ovSubheader}>
                                    Result
                                </div>
                                <div class={miCasaStyles.ovSubheaderText}>
                                    A mobile-first, educative<br/> website built in Gatsby
                                </div>
                            </div>
                            <div class={miCasaStyles.ovSubheaderItem}>
                                <div class={miCasaStyles.ovSubheader}>
                                    My role
                                </div>
                                <div class={miCasaStyles.ovSubheaderText}>
                                    Full stack web developer
                                </div>
                            </div>
                            <div class={miCasaStyles.ovSubheaderItem}>
                                <div class={miCasaStyles.ovSubheader}>
                                    The team
                                </div>
                                <div class={miCasaStyles.ovSubheaderText}>
                                    Hannan Abdi
                                </div>
                            </div>
                            <div class={miCasaStyles.ovSubheaderItem} id={miCasaStyles.ovSubheaderItemNoMargin}>
                                <div class={miCasaStyles.ovSubheader}>
                                    Tools
                                </div>
                                <div class={miCasaStyles.ovSubheaderText}>
                                    React, Gatsby, Figma
                                </div>
                            </div>
                        </div>

                        <div id={miCasaStyles.timelineContainer}>
                            <div class={miCasaStyles.ovSubheader}>
                                        Timeline
                            </div>
                            <div class={miCasaStyles.ovSubheaderText}>
                                    Dec 2020 - May 2021 <span style={{color: "#8E8E8E"}}>(ongoing)</span>
                            </div>
                            <StaticImage 
                                src="../assets/micasa/timeline.png"
                                alt="Timeline of project work"
                                className={miCasaStyles.timelineImg}
                                quality={100}
                            />
                        </div>

                        <div id={miCasaStyles.clientContainer}>
                            <div id={miCasaStyles.clientTextContainer}>
                                <div class={miCasaStyles.ovSubheader}>
                                    Client
                                </div>
                                <div class={miCasaStyles.ovSubheaderText}>
                                    <a href="https://www.welcomingatlanta.com/" target="_blank" rel="noreferrer">The Atlanta Mayor’s Office of Immigrant Affairs</a> <br/> <span id={miCasaStyles.clientSubtext} >(Known as Welcoming Atlanta)</span>
                                </div>
                                <div class={miCasaStyles.ovSubheaderText} id={miCasaStyles.clientBody}>
                                    Welcoming Atlanta provides direct service work to immigrants in Atlanta, offering personal guidance and support, running food drives, connecting residents with legal and victims counseling, among a host of other assistance measures. <span style={{color: "var(--blue-text)"}}>Their constituency is largely Latinx, low-income folks who may or may not be documented.</span>
                                </div>
                            </div>
                            <div id={miCasaStyles.watlWrapper}>
                                <StaticImage 
                                    src="../assets/micasa/WATLlogo.png"
                                    alt="Logo of Welcoming Atlanta"
                                    class={miCasaStyles.watlLogo}
                                    placeholder={"blurred"}
                                    quality={100}
                                />
                            </div>
                        </div>

                        <div class={miCasaStyles.sectionContainer}>
                            <div class={miCasaStyles.pageSubheader}>
                                Problem
                            </div>
                            <div class={miCasaStyles.problemContent}>
                                <div class={miCasaStyles.ovSubheaderText} id={miCasaStyles.problemBody}>
                                    While the NoMasDeslojos.com site we built (see project) was successful in helping Welcoming Atlanta’s constituents file the CDC moratorium on evictions and stay in their homes, we knew that this was a last resort — and far from the ideal. 
                                    <br/><br/>
                                    <span class={miCasaStyles.blueText}>So, how do we support tenants in avoiding an eviction order in the first place, and to feel confident in knowing and exercising their rights?</span>
                                </div>
                                <StaticImage 
                                        src="../assets/micasa/problemImg.png"
                                        alt="Illustration of concerned woman"
                                        class={miCasaStyles.problemImg}
                                        placeholder={"blurred"}
                                        quality={100}
                                />
                            </div>
                        </div>
                        
                        <div class={miCasaStyles.sectionContainer}>
                            <div class={miCasaStyles.pageSubheader}>
                                Solution
                            </div>
                            <div class={miCasaStyles.ovSubheaderText} id={miCasaStyles.solutionBody}>
                                Mi Casa Mis Derechos (My House My Rights in English) is a mobile-first, Spanish language website that visually communicates tenants’ rights and resources through a series of illustrated stories. 
                                <br/><br/>
                                <span class={miCasaStyles.blueText}>The solution helps the user discover local resources (financial aid, legal services, etc.) and their basic tenant rights, and understand how they can take immediate action to protect themselves and their home.</span>
                                <br/><br/>
                            </div>
                            <div id={miCasaStyles.award} >
                                <div id={miCasaStyles.star}>
                                    <StaticImage 
                                            src="../assets/micasa/star.png"
                                            alt="Star"
                                            class={miCasaStyles.star}
                                            placeholder={"blurred"}
                                            quality={100}
                                    />
                                </div>
                                <div id={miCasaStyles.awardText}>
                                    This project was awarded the best team Master’s project in the MS HCI program at Georgia Tech.
                                </div>
                            </div>
                        </div>

                        <div class={miCasaStyles.gifBlock} id={miCasaStyles.firstGifBlock}>
                            <div class={miCasaStyles.gifText}>
                                Learn through stories
                                <div class={miCasaStyles.gifBody}>
                                    Mi Casa Mis Derechos (MCMD) teaches users what their tenants' rights are and how to exercise them by using characters and stories that they can identify with.
                                </div>
                            </div>
                            <div class={miCasaStyles.overviewGif}>
                                <video autoPlay loop muted playsInline src={learnVideo}></video>
                            </div>
                        </div>

                        <div class={miCasaStyles.gifBlock}>
                            <div class={`${miCasaStyles.overviewGif} ${miCasaStyles.orderTwo}`}>
                                <video autoPlay loop muted playsInline src={resourcesVideo}></video>
                            </div>
                            <div class={miCasaStyles.gifText}>
                                Find local resources
                                <div class={miCasaStyles.gifBody}>
                                    Need assistance? MCMD has a list of Atlanta organizations ready to help. The guide also indicates which organizations speak Spanish, and the right contact to reach out to.
                                </div>
                            </div>
                        </div>

                        <div class={miCasaStyles.gifBlock}>
                            <div class={miCasaStyles.gifText}>
                                Tap for details
                                <div class={miCasaStyles.gifBody}>
                                    MCMD uses interactive tooltips to provide in-context, plain language definitions of legal terms, as well as offer contact information to vital resources.
                                </div>
                            </div>
                            <div class={miCasaStyles.overviewGif}>
                                <video autoPlay loop muted playsInline src={tooltipsVideo}></video>
                            </div>
                        </div>

                        <div class={miCasaStyles.sectionContainer}>
                            <div class={miCasaStyles.pageSubheader}>
                                Link to website
                            </div>
                            <div class={miCasaStyles.ovSubheaderText} id={miCasaStyles.linkBody}>
                                You can access the website at <a href="http://mis-derechos.surge.sh/" target="_blank" rel="noreferrer">this link</a>.<br/><br/>Please note that Mi Casa Mis Derechos is still under active development, and is currently intended for mobile use!
                            </div>
                        </div>

                        <div class={miCasaStyles.sectionContainer} id={miCasaStyles.contributionContainer} >
                            <div class={miCasaStyles.pageSubheader}>
                                My contribution
                            </div>
                            <div id={miCasaStyles.contributionContent}>
                                <div class={miCasaStyles.ovSubheaderText} id={miCasaStyles.contributionBody}>
                                    <span class={miCasaStyles.blueText}>I was the sole developer of Mi Casa Mis Derechos, from choosing Gatsby as the development framework, to coding and deploying the website online.</span> I played a supporting role in the UX design and user testing of the website, and wrote the technical documentation for MCMD.
                                </div>
                                <StaticImage 
                                        src="../assets/micasa/contribution.png"
                                        alt="Illustration of a code editor"
                                        class={miCasaStyles.contributionImg}
                                        placeholder={"blurred"}
                                        quality={100}
                                />
                            </div>
                        </div>

            </div> {/* End of overview content div */}
        </section>

        <section id={miCasaStyles.outterDetailsContainer}>
            <div id={miCasaStyles.innerDetailsContainer}>
                        <div class = {miCasaStyles.sectionHeader}>
                            Details
                        </div>
                        <div class={`${miCasaStyles.pageSubheader} ${miCasaStyles.topMar6}`}>
                                Research
                        </div>
                        <div class={miCasaStyles.bodyText}>
                            The vast majority of the user research for this project was done in the previous semester while we were building the NoMasDesalojos website. If you are interested in that process, I recommend checking out the <Link to="/no-more-evictions"> No More Evictions project page</Link>. 
                        </div>

                        <div class={miCasaStyles.indentedContainer}>
                            <div class={miCasaStyles.indentedInner}>
                                <div class={miCasaStyles.blueBar1}/>
                                <div class={miCasaStyles.detailsHeader}>
                                    Understanding the problem
                                </div>
                                <div class={miCasaStyles.bodyText}>
                                In the first semester of our work with Welcoming Atlanta, we were focused on meeting the immediate need of stopping community members who were in crisis from being illegally evicted. And while the NoMasDesalojos site is a boon in handling this, it is largely a stopgap. <span class={miCasaStyles.blueText}>Our ultimate goal was to support residents in preventing an eviction notice in the first place, which means supporting them in exercising their rights as Atlanta tenants, and getting them the help they need before crisis strikes.</span>
                                <br/><br/>
                                So, why isn’t this happening already? Here’s what we learned from our research:
                                </div>
                            </div>
                            </div>
                            <div id={miCasaStyles.primaryIssues}>
                                <div class={miCasaStyles.figureTitle}>
                                    Primary issues
                                </div>
                                <div id={miCasaStyles.primaryIssuesFlex}>
                                    <div class={miCasaStyles.primaryIssuesItem}>
                                        <div class={miCasaStyles.issueImgWrapper}>
                                            <StaticImage 
                                                src="../assets/micasa/puzzle.png"
                                                alt="Puzzle with a missing piece"
                                                class={miCasaStyles.puzzleImg}
                                                placeholder={"blurred"}
                                                quality={100}
                                            />
                                        </div>
                                        <div class={miCasaStyles.bodyTextSemiBold}>
                                            Info is often incomplete or conflicting
                                        </div>
                                        <div class={miCasaStyles.downArrow}>
                                            <img src={downArrow} alt="Arrow pointing down"/>
                                        </div>
                                        <div class={`${miCasaStyles.bodyTextLight} ${miCasaStyles.borderedText}`} >
                                            Online aid info is often inapplicable for immigrants and out of date. <br/><br/>Word of mouth information on assistance programs is often distrusted.
                                        </div>
                                    </div>
                                    <div class={miCasaStyles.primaryIssuesItem}>
                                        <div class={miCasaStyles.issueImgWrapper}>
                                            <StaticImage 
                                                src="../assets/micasa/anxiety.png"
                                                alt="Person looking anxious"
                                                class={miCasaStyles.anxietyImg}
                                                placeholder={"blurred"}
                                                quality={100}
                                            />
                                        </div>
                                        <div class={miCasaStyles.bodyTextSemiBold}>
                                        Residents feel anxious taking action
                                        </div>
                                        <div class={miCasaStyles.downArrow}>
                                            <img src={downArrow} alt="Arrow pointing down"/>
                                        </div>
                                        <div class={`${miCasaStyles.bodyTextLight} ${miCasaStyles.borderedText}`} >
                                            Community members are fearful of making a mistake, and are anxious of dealing with the justice system. <br/><br/>Tenants also worry about backlash from landlords.
                                        </div>
                                    </div>
                                    <div class={miCasaStyles.primaryIssuesItem}>
                                        <div class={miCasaStyles.issueImgWrapper}>
                                            <StaticImage 
                                                src="../assets/micasa/computerX.png"
                                                alt="Computer monitor with an X through browser"
                                                class={miCasaStyles.computerXImg}
                                                placeholder={"blurred"}
                                                quality={100}
                                            />
                                        </div>
                                        <div class={miCasaStyles.bodyTextSemiBold}>
                                            Rights information is inaccessible
                                        </div>
                                        <div class={miCasaStyles.downArrow}>
                                            <img src={downArrow} alt="Arrow pointing down"/>
                                        </div>
                                        <div class={`${miCasaStyles.bodyTextLight} ${miCasaStyles.borderedText}`} >
                                            Rights info is typically written in English legalese, inaccessible to residents. <br/><br/>Online information websites are complex and difficult to navigate.
                                        </div>
                                    </div>
                                </div>

                                <div class={`${miCasaStyles.bodyText} ${miCasaStyles.mobileMarginTop4}`}>
                                    Together, these problems prevent Atlanta tenants from understanding their rights, using that knowledge to take action, or getting the assistance they need. We called this problem the information-to-action gap.
                                </div>

                            </div>
                        

                        <div class={miCasaStyles.indentedContainer}>
                            <div class={miCasaStyles.indentedInner}>
                                <div class={miCasaStyles.blueBar2}/>
                                <div class={miCasaStyles.detailsHeader}>
                                    Competitive Analysis
                                </div>
                                <div class={miCasaStyles.bodyText}>
                                    Before jumping into the design work, we wanted a better understanding of the landscape of tools that are intended to help vulnerable folks understand their rights.
                                    <br/><br/>
                                    We found two types of such tools:
                                </div>
                            </div>
                        </div>

                        <div id={miCasaStyles.competitiveAnalysisContainer}>
                            <div class={miCasaStyles.CAItem}>
                                <div class={miCasaStyles.CAHeader}>
                                    <span class={miCasaStyles.blueBullet}/>
                                    Camp 1: direct, one-size-fits-all technical resources
                                </div>
                                <div class={miCasaStyles.bodyText}>
                                    This camp attempts to provide general information to the public about their legal rights in specific situations, or within certain geographies. They use ample legalese and technical jargon, and are largely impersonal.
                                    <br/><br/>
                                    <span class={miCasaStyles.blueText}> We find this camp to be largely unhelpful for Welcoming Atlanta’s community members.</span> The explanations are dry, unrelatable, and challenging to follow if you are unfamiliar with the legal system.
                                </div>
                                <div>
                                    <div class={miCasaStyles.CAEmbed}>
                                        <iframe class={miCasaStyles.YTiframe} src="https://www.youtube.com/embed/H3zxT_8-NKc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                    </div>
                                </div>
                                <div class={miCasaStyles.imageCaption}>
                                    Example of camp 1: the Fulton County Visual Information Center videos
                                </div>
                            </div>
                            <div class={miCasaStyles.CAItem}>
                                <div class={miCasaStyles.CAHeader}>
                                    <span class={miCasaStyles.blueBullet}/>
                                    Camp 2: contextual, story-driven and visual tools
                                </div>
                                <div class={miCasaStyles.bodyText}>
                                    This camp uses storytelling and visual elements to help users break down and understand the information they need to know.
                                    <br/><br/>
                                    A key example that we kept coming back to for inspiration is the ACLU’s video series “We have Rights.” 
                                    <span class={miCasaStyles.blueText}> The narrative and visuals are not only compelling to watch, but the story helps the viewer contextualize the information into real world scenarios</span> where they may need to use the knowledge provided.
                                </div>
                                <div>
                                    <div class={miCasaStyles.CAEmbed}>
                                        <iframe class={miCasaStyles.YTiframe} src="https://www.youtube.com/embed/pW4uixi4b_E" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                    </div>
                                </div>
                                <div class={miCasaStyles.imageCaption}>
                                    Example of camp 2: the ACLU’s video series “We have rights.”
                                </div>
                            </div>
                            <div class={`${miCasaStyles.bodyText} ${miCasaStyles.marginTop3}`}>
                                We knew we wanted to create something squarely in camp 2, and pitched the idea of potentially using visual storytelling to Welcoming Atlanta. They were keen on the idea, and gave us the greenlight.
                            </div>
                        </div>

                        <div class={`${miCasaStyles.pageSubheader} ${miCasaStyles.topMar6}`}>
                            System requirements
                        </div>
                        <div class={miCasaStyles.bodyText}>
                            Based on the user research data collected and analyzed in the first semester, we developed the following design criteria.
                        </div>
                        <div id={miCasaStyles.sysRequirements}>
                            <div class={` ${miCasaStyles.sysRItem} ${miCasaStyles.bodyText}`}>
                                <div><span style={{fontWeight: "bold", marginRight: "8px"}}>1. </span>System should allow users to discover resources in context</div>
                            </div>
                            <div class={` ${miCasaStyles.sysRItem} ${miCasaStyles.bodyText}`}>
                                <div><span style={{fontWeight: "bold", marginRight: "8px"}}>2. </span>System hould accommodate low literacy users</div>
                            </div>
                            <div class={` ${miCasaStyles.sysRItem} ${miCasaStyles.bodyText}`}>
                                <div><span style={{fontWeight: "bold", marginRight: "8px"}}>3. </span>System should use stories that are authentic to users</div>
                            </div>
                            <div class={` ${miCasaStyles.sysRItem} ${miCasaStyles.bodyText}`}>
                                <div><span style={{fontWeight: "bold", marginRight: "8px"}}>4. </span>System should acknowledge multiple courses of action</div>
                            </div>
                            <div class={` ${miCasaStyles.sysRItem} ${miCasaStyles.bodyText}`}>
                                <div><span style={{fontWeight: "bold", marginRight: "8px"}}>5. </span>System must include clear steps on how to manage crisis</div>
                            </div>
                        </div>

                        <div class={`${miCasaStyles.pageSubheader} ${miCasaStyles.topMar6}`}>
                            Design
                        </div>
                        <div class={miCasaStyles.bodyText}>
                            With these design requirements in mind, it was time to pull out the drawing board. We workshopped ideas, hashing out design components that would satisfy the design requirements while at the same time discussing the feasibility of implementation. 
                            <br/><br/>
                            At each stage of the design process, the resulting artifact — a sketch, wireframe, mockup, or prototype — was used to collect feedback. We sought feedback from the client, our advisors, and usability testing participants.
                        </div>

                        <div class={miCasaStyles.indentedContainer}>
                            <div class={miCasaStyles.indentedInner}>
                                <div class={miCasaStyles.blueBar3}/>
                                <div class={miCasaStyles.detailsHeader}>
                                    Sketches
                                </div>
                                <div class={miCasaStyles.bodyText}>
                                    We started with rough sketches where we established design components, such as the chapter select page, story overview, and reading modes seen below.
                                </div>
                            </div>
                        </div>

                        <div id={miCasaStyles.sketchesContainer}>
                            <StaticImage 
                                src="../assets/micasa/sketch1.png"
                                alt="Sketch 1"
                                class={miCasaStyles.sketchImg}
                                placeholder={"blurred"}
                                quality={100}
                            />
                            <StaticImage 
                                src="../assets/micasa/sketch2.png"
                                alt="Sketch 2"
                                class={miCasaStyles.sketchImg}
                                placeholder={"blurred"}
                                quality={100}
                            />
                            <StaticImage 
                                src="../assets/micasa/sketch3.png"
                                alt="Sketch 3"
                                class={miCasaStyles.sketchImg}
                                placeholder={"blurred"}
                                quality={100}
                            />
                        </div>

                        <div class={miCasaStyles.indentedContainer}>
                            <div class={miCasaStyles.indentedInner}>
                                <div class={miCasaStyles.blueBar1}/>
                                <div class={miCasaStyles.detailsHeader}>
                                    Wireframes
                                </div>
                                <div class={miCasaStyles.bodyText}>
                                    Next we translated the sketches into wireframes to establish user flow and create a blueprint for site architecture and content.
                                </div>
                            </div>
                        </div>

                        <div id={miCasaStyles.wireframesContainer}>
                            <StaticImage 
                                src="../assets/micasa/wireframes.png"
                                alt="Wireframes of the application"
                                class={miCasaStyles.wireframesImg}
                                placeholder={"blurred"}
                                quality={100}
                             />  
                        </div>

                        <div class={miCasaStyles.indentedContainer}>
                            <div class={miCasaStyles.indentedInner}>
                                <div class={miCasaStyles.blueBar2}/>
                                <div class={miCasaStyles.detailsHeader}>
                                    Prototype
                                </div>
                                <div class={miCasaStyles.bodyText}>
                                    Finally, we created an interactive Figma prototype of the site to communicate the look and feel of the final product, conduct user testing, and to dictate specs for the build.
                                    <br/><br/>
                                    Feel free to test out to the embedded prototype below!
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class={miCasaStyles.FigmaEmbed}>
                                <iframe style={{border: "0px solid rgba(0, 0, 0, 0.1)"}} title="Figma prototype" class={miCasaStyles.Figmaiframe} src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FTd9oGICRfjii0pEdJ42fHH%2FMi-Casa-Mis-Derechos-Prototype%3Fpage-id%3D936%253A1%26node-id%3D936%253A2%26scaling%3Dscale-down" allowfullscreen></iframe>
                            </div>
                        </div>

                        <div class={`${miCasaStyles.pageSubheader} ${miCasaStyles.marginTop4}`}>
                            User testing
                        </div>
                        <div class={miCasaStyles.bodyText}>
                            Using the interactive prototype, <span class={miCasaStyles.blueText}>we developed a formal think aloud evaluation protocol to gather data on usability and attitudes on perceived efficacy</span> (how effective the test user thinks the solution will be in changing the behavior of the target audience).
                        </div>
                        <div class={`${miCasaStyles.figureTitle} ${miCasaStyles.marginTop4} `}>
                            Participants
                        </div>
                        <div id={miCasaStyles.participantsOutter}>
                            <div class={miCasaStyles.participantsContainer}>
                                <div class={miCasaStyles.participantsItem}>
                                    <div class={miCasaStyles.bodyText} style={{fontWeight: "bold", marginBottom: "4px"}}>
                                        3
                                    </div>
                                    <div class={miCasaStyles.bodyText}>
                                        Welcoming Atlanta staff
                                    </div>
                                    <StaticImage 
                                        src="../assets/micasa/3participants.png"
                                        alt="Three participants"
                                        className={miCasaStyles.participants3Img}
                                        placeholder={"blurred"}
                                    />  
                                </div>

                                <div class={miCasaStyles.participantsItem}>
                                    <div class={miCasaStyles.bodyText} style={{fontWeight: "bold", marginBottom: "4px"}}>
                                        1
                                    </div>
                                    <div class={miCasaStyles.bodyText}>
                                        Volunteer
                                    </div>
                                    <StaticImage 
                                        src="../assets/micasa/1participant.png"
                                        alt="One participant"
                                        className={miCasaStyles.participants1Img}
                                        placeholder={"blurred"}
                                    />  
                                </div>
                            </div>

                            <div class={miCasaStyles.participantsContainer}>

                            <div class={miCasaStyles.participantsItem}>
                                    <div class={miCasaStyles.bodyText} style={{fontWeight: "bold", marginBottom: "4px"}}>
                                        1
                                    </div>
                                    <div class={miCasaStyles.bodyText}>
                                        Community member
                                    </div>
                                    <StaticImage 
                                        src="../assets/micasa/1participant.png"
                                        alt="One participant"
                                        className={miCasaStyles.participants1Img}
                                        placeholder={"blurred"}
                                    />  
                                </div>

                                <div class={miCasaStyles.participantsItem} id={miCasaStyles.participantsLast}>
                                    <div class={miCasaStyles.bodyText} style={{fontWeight: "bold", marginBottom: "4px"}}>
                                        4
                                    </div>
                                    <div class={miCasaStyles.bodyText}>
                                        Community navigators
                                    </div>
                                    <StaticImage 
                                        src="../assets/micasa/4participants.png"
                                        alt="Four participants"
                                        className={miCasaStyles.participants4Img}
                                        placeholder={"blurred"}
                                    />  
                                </div>
                            </div>
                        </div>

                        <div class={miCasaStyles.flexBox}>
                            <StaticImage 
                                src="../assets/micasa/userTesting.png"
                                alt="Community navigator testing the application in the field"
                                className={miCasaStyles.userTestingImg}
                                placeholder={"blurred"}
                            />  
                        </div>
                        <div class={miCasaStyles.imageCaption2}>
                            Conducting user testing with a community navigator at a Welcoming Atlanta food distribution event
                        </div>

                        <div class={miCasaStyles.indentedContainer}>
                            <div class={`${miCasaStyles.indentedInner} ${miCasaStyles.marginTop2}`}>
                                <div class={miCasaStyles.blueBar3}/>
                                <div class={miCasaStyles.detailsHeader}>
                                    Results
                                </div>
                                <div class={miCasaStyles.bodyText}>
                                    Testing was valuable in both validating our concept and revealing specific opportunities where we didn’t quite hit the mark. Some of the feedback is summarized below:
                                </div>
                            </div>
                        </div>

                        <div class={`${miCasaStyles.figureTitle} ${miCasaStyles.marginTop3} `}>
                            Core themes
                        </div>

                        <div class={miCasaStyles.flexBox}>
                            <StaticImage 
                                    src="../assets/micasa/results.png"
                                    alt="Results"
                                    placeholder={"blurred"}
                                    className={miCasaStyles.resultsImg}
                                />  
                        </div>

                        <div class={`${miCasaStyles.bodyText} ${miCasaStyles.indentedText} ${miCasaStyles.marginTop4}` }>
                            One Community Navigator went so far as to offer the following positive feedback during our follow-up questions, and it really struck a chord with us:
                        </div>

                        <div class={miCasaStyles.indentedContainer} id={miCasaStyles.blockQuote}>
                            <div class={miCasaStyles.indentedInner}>
                                <div class={miCasaStyles.quoteBar}/>
                                <div class={miCasaStyles.quoteText}>
                                    “The story is really happening — it almost feels like it is happening to somebody, so this is really what is happening in real life. While reading, it feels like I was going through it myself… We know we have rights and there are organizations that can help us. But ourselves, we have to speak and communicate to learn more about our rights.”
                                </div>
                            </div>
                            <div class={miCasaStyles.quoteText} style={{textAlign:"right", marginTop:"2rem", fontStyle: "normal"}}>
                                    –Community navigator
                            </div>
                        </div>


                        <div class={`${miCasaStyles.pageSubheader} ${miCasaStyles.marginTop6}`}>
                            Technical implementation
                        </div>

                        <div class={miCasaStyles.problemContent}>
                                <div class={miCasaStyles.bodyText}>
                                    For Mi Casa Mis Derechos, we decided to build it in Gatsby, a front-end framework built on top of React.
                                    <br/><br/>
                                    Mi Casa Mis Derechos is a static website by nature, so Gatsby, which is a static site generator with lots of optimization built in, was a natural fit. <span class={miCasaStyles.blueText}> Gatsby’s load times were particularly attractive since many of our users are accessing the site from low-bandwidth mobile devices.</span> Lastly, since I was the only developer on the team, and given the short amount of time we had, having access to a rich ecosystem like React’s was extremely helpful.
                                    <br/><br/>
                                    The code for this project is public on Github – <a href="https://github.com/rwinstead/MisDerechos" target="_blank" rel="noreferrer" >you can check it out here</a>.
                                    <br/><br/>
                                    If you are working on something similar or would like to otherwise chat about the implementation of the site, feel free to get in touch!
                                </div>
                                <StaticImage 
                                        src="../assets/micasa/gatsby.png"
                                        alt="Illustration of concerned woman"
                                        className={miCasaStyles.gatsbyImg}
                                        placeholder={"blurred"}
                                        quality={100}
                                />
                            </div>

                        <div class={`${miCasaStyles.pageSubheader} ${miCasaStyles.marginTop6}`}>
                            Final solution and roadmap
                        </div>

                        <div class={miCasaStyles.bodyText}>
                            The website is currently under active development; however, at this time, you can access it <a href="http://mis-derechos.surge.sh/" target="_blank" rel="noreferrer">here</a>.
                        </div>

                        <div class={`${miCasaStyles.figureTitle} ${miCasaStyles.marginTop5} `}>
                            Upcoming features
                        </div>

                        <div class={miCasaStyles.flexBox}>
                            <div id={miCasaStyles.roadmapContainer}>
                                <div class={`${miCasaStyles.roadmap} ${miCasaStyles.bodyText}`}>
                                    <span class={miCasaStyles.blueBullet}/>
                                    <span>Full desktop support</span>
                                </div>
                                <div class={`${miCasaStyles.roadmap} ${miCasaStyles.bodyText}`}>
                                    <span class={miCasaStyles.blueBullet} style={{marginTop:"-24px"}}/>
                                    A second story (currently being written by the Atlanta Volunteer Lawyer Assocation)
                                </div>
                                <div class={`${miCasaStyles.roadmap} ${miCasaStyles.bodyText}`}>
                                    <span class={miCasaStyles.blueBullet}/>
                                    English version
                                </div>
                                </div>
                         </div>
                         
                        <div id={miCasaStyles.buttonsContainer} class={`${miCasaStyles.flexBox} ${miCasaStyles.marginTop9}`} style={{paddingBottom: "1rem"}}>
                            <Link to="/" class={miCasaStyles.linkButton}>
                                <div id={miCasaStyles.takeMeHome} class={miCasaStyles.botButton}>
                                    Take me home
                                </div>
                            </Link>
                            <Link to={`/my-house-my-rights#${miCasaStyles.topPageContainer}`}>
                                <div id={miCasaStyles.backTop} class={miCasaStyles.botButton}>
                                    Back to top
                                </div>
                            </Link>
                         </div>
                        


            </div>{/* End of details content div */}
        </section>


        <Footer/>
    </main>
)

export default MiCasa